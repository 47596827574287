import * as t from 'io-ts';
import { booleanFromNumber0or1, booleanFromString0or1, nullableArray, numberFromString, stringEnum } from 'tccc-utils';
import { NumberReflectModeC } from '../../constants/appSettings';

export enum StaffStatus {
  Offline = '0',
  Free = '100',
  Busy = '200',
  Rest = '300',
  AfterWorkCountDown = '400',
  AfterWork = '500',
  NotReady = '700',
}
export const Staff = t.type({
  avatar: t.string,
  ctime: numberFromString,
  kickOff: booleanFromString0or1,
  mobile: t.string,
  mtime: numberFromString,
  nickName: t.string,
  roleId: t.string,
  sdkAppId: t.string,
  skillGroupId: t.string,
  staffName: t.string,
  staffNo: t.string,
  useMobile: t.string, // '0 1 2'
  userId: t.string,
  wechatMiniProgramAccept: booleanFromString0or1,
  status: t.union([t.undefined, stringEnum(StaffStatus)]),
});

export type StaffTypes = t.TypeOf<typeof Staff>;

export const SkillGroup = t.type({
  skillGroupId: t.string,
  skillGroupName: t.string,
  extraFlag: t.union([t.literal('IM'), t.literal('ALL'), t.literal('TEL')]),
  ctime: t.string,
  mtime: t.string,
  servingNum: t.string,
  maxConcurrency: t.string,
});

export const SupervisorPermissionGroupKeys = t.union([
  t.literal('TEL_BILL_STAT_DASHBOARD'),
  t.literal('SEAT_STAT_DASHBOARD'),
  t.literal('VIDEO_STAT_DASHBOARD'),
  t.literal('OVERVIEW'),
  t.literal('TEL_SEAT_STATS'),
  t.literal('IM_SEAT_STATS'),
  t.literal('RADIO_SEAT_STATS'),
  t.literal('VIDEO_SEAT_STATS'),
  t.literal('ALL_SEAT_STATS'),
  t.literal('SEAT_STATUS_TRACE_STATS'),
  t.literal('NUMBER_HEALTH_STATS'),
  t.literal('TEL_SEAT_CDR'),
  t.literal('IM_SEAT_SERVICE_LOG'),
  t.literal('RADIO_SEAT_SERVICE_LOG'),
  t.literal('VIDEO_SEAT_SERVICE_LOG'),
  t.literal('INTERNAL_LINE_SERVICE_LOG'),
  t.literal('SEAT_MOINTOR'),
  t.literal('SKILL_GROUP_MONITOR'),
  t.literal('REALTIME_CALL_MONITOR'),
]);
export const AdminPermissionGroupKeys = t.union([
  t.literal('IM_SKILL_GROUP_CONFIG'),
  t.literal('IM_SESSION_FLOW_CONFIG'),
  t.literal('IM_STATIS_CONFIG'),
  t.literal('IM_CHANNEL_CONFIG'),
  t.literal('IM_SEAT_CONFIG'),
  t.literal('IM_TEXT_ROBOT_CONFIG'),
  t.literal('TEL_SKILL_GROUP_CONFIG'),
  t.literal('TEL_IVR_CONFIG'),
  t.literal('TEL_STATIS_CONFIG'),
  t.literal('TEL_NUMBER_CONFIG'),
  t.literal('TEL_CALLOUT_TASK'),
  t.literal('TEL_SEAT_CONFIG'),
  t.literal('TEL_SELF_HELP_CONFIG'),
  t.literal('TEL_DTMF_CALLBACK_CONFIG'),
  t.literal('TEL_RADIO_FILE_CONFIG'),
  t.literal('TEL_QC_CONFIG'),
  t.literal('TEL_SIP_DEVICE_CONFIG'),
  t.literal('RADIO_SKILL_GROUP_CONFIG'),
  t.literal('RADIO_IVR_CONFIG'),
  t.literal('RADIO_SELF_HELP_CONFIG'),
  t.literal('RADIO_RADIO_FILE_CONFIG'),
  t.literal('RADIO_CHANNEL_CONFIG'),
  t.literal('RADIO_SEAT_CONFIG'),
  t.literal('VIDEO_SKILL_GROUP_CONFIG'),
  t.literal('VIDEO_CHANNEL_CONFIG'),
  t.literal('VIDEO_SEAT_CONFIG'),
  t.literal('CALL_SCREEN_CONFIG'),
  t.literal('QUICK_REPLY_CONFIG'),
  t.literal('BLACKLIST_CONFIG'),
  t.literal('BASIC_CONFIG'),
  t.literal('SEAT_CONFIG'),
  t.literal('CLIENT_CONFIG'),
  t.literal('CLIENT_INFO_CONFIG'),
  t.literal('PERMISSION_CONFIG'),
  t.literal('CRM_ALL'),
  t.literal('ASR_HOT_WORDS_CONFIG'),
]);
export const PermissionGroupKey = t.union([
  t.literal('*'),
  SupervisorPermissionGroupKeys,
  AdminPermissionGroupKeys,
  // API role
  t.literal('LEGANCY_REVIEWER'), // Supervisor,
  t.literal('LEGANCY_ADMIN'), // Admin,
]);

export type SkillGroupTypes = t.TypeOf<typeof SkillGroup>;

export const RoleItem = t.type({
  roleId: t.string,
  roleName: t.string,
});

const AppSettingsC = t.partial({
  hideCalloutNumber: booleanFromString0or1,
  hideCallinNumber: booleanFromString0or1,
  callTimeout: numberFromString,
  audioTimeout: numberFromString,
  videoTimeout: numberFromString,
  audioEndCountdown: numberFromString,
  audioAutoAccept: t.boolean,
  mobileSwitchDisplay: booleanFromString0or1,
  imTimeout: numberFromString,
  endCountdown: numberFromString,
  imEndCountdown: numberFromString,
  omEndCountdown: numberFromString,
  callOutNum: t.string,
  imAutoAccept: booleanFromString0or1,
  telAutoAccept: booleanFromString0or1,
  smsPrivilege: booleanFromString0or1,
  telCallinPostIVR: booleanFromString0or1,
  telCalloutPostIVR: booleanFromString0or1,
  loginStaffStatus: t.string,
  numberReflectMode: NumberReflectModeC,
  asr: t.boolean,
  seatType: t.union([t.literal(1), t.literal(2), t.literal(3), t.literal(4), t.literal(5), t.literal(6), t.literal(7)]),
  realtimeAsr: t.boolean,
  autoRealtimeAsr: t.boolean,
  telBlackUserEnable: t.boolean,
  staffAssignedCalloutNumber: t.boolean,
  imAppType: t.number,
  hasUseMobileStaff: t.boolean,
});

export const adminAPIs = {
  '/tcccadmin/app/getAppInfo': {
    Input: t.type({}),
    Output: AppSettingsC,
  },
  '/tcccadmin/staff/getRestReasonList': {
    Input: t.type({}),
    Output: t.type({
      restReasonList: t.array(
        t.type({
          name: t.string,
          restId: t.string,
          sortId: t.string,
          state: t.string,
        }),
      ),
    }),
  },
  '/tcccadmin/staff/getRoleList': {
    Input: t.partial({
      pageNum: numberFromString,
      pageSize: numberFromString,
    }),
    Output: t.type({
      roleList: t.array(
        t.intersection([
          t.type({
            roleId: t.string,
            roleName: t.string,
          }),
          t.partial({
            permissionList: t.array(t.string),
            staffList: t.array(t.string),
            skillGroupPermissionList: t.array(
              t.type({
                permission: t.union([t.literal('*'), t.array(t.string)]),
                skillGroupList: t.array(t.number),
              }),
            ),
          }),
        ]),
      ),
      total: t.string,
    }),
  },
  '/tcccadmin/staff/getStaffList': {
    Input: t.type({
      userId: t.string,
      staffName: t.string,
      roleId: t.string,
      mobile: t.string,
      staffNo: t.string,
      batchUserIds: t.union([t.undefined, t.array(t.string)]),
      skillGroupId: t.union([t.undefined, t.string]),
      fuzzingKeyWord: t.union([t.undefined, t.string]),
      pageSize: t.union([t.undefined, numberFromString]),
      orderField: t.union([t.undefined, t.string]),
      orderType: t.union([t.undefined, t.string]),
      pageNum: t.union([t.undefined, numberFromString]),
      isNeedStatus: t.union([t.undefined, t.boolean]),
      status: t.array(t.number),
      statusRefreshTime: t.union([
        t.undefined,
        t.type({
          from: t.string,
          to: t.string,
        }),
      ]),
    }),
    Output: t.type({
      total: numberFromString,
      staffList: nullableArray(Staff),
    }),
  },
  '/tcccadmin/app/getInternalCdr': {
    Input: t.type({
      staff: t.type({
        sdkAppId: t.string,
        userId: t.string,
      }),
      startTimestamp: t.number,
      endTimestamp: t.number,
      caller: t.string,
      callee: t.string,
      user: t.string,
    }),
    Output: t.type({
      total: t.number,
      data: t.union([
        t.null,
        t.array(
          t.type({
            beginTimestamp: t.number,
            endTimestamp: t.number,
            caller: t.string,
            callee: t.string,
          }),
        ),
      ]),
    }),
  },
  '/tcccadmin/tech/getSkillGroupInfo': {
    Input: t.type({
      sdkAppId: t.string,
      skillGroupIdList: t.array(t.string),
    }),
    Output: t.type({
      skillGroupList: t.array(SkillGroup),
    }),
  },
  '/tcccadmin/tech/getSkillGroupList': {
    Input: t.partial({
      pageNum: numberFromString,
      pageSize: numberFromString,
      fuzzingKeyWord: t.string,
      skillGroupType: t.number,
      extraFlag: t.string,
      ringAll: t.number,
      orderBy: t.array(t.type({ field: t.string, asc: t.boolean })),
      freeOnly: t.boolean,
    }),
    Output: t.type({
      total: numberFromString,
      skillGroupList: nullableArray(
        t.intersection([
          t.type({
            skillGroupId: t.string,
            skillGroupName: t.string,
            skillGroupType: t.number,
            ringAll: t.boolean,
            extraFlag: t.string,
          }),
          t.partial({
            freeStaffCount: t.number,
            mobileStaffCount: t.number,
          }),
        ]),
      ),
    }),
  },
  '/tcccadmin/user/getUserMark': {
    Input: t.type({
      sdkAppId: t.string,
      userId: t.string,
    }),
    Output: t.type({
      userMark: t.string,
    }),
  },
  '/tcccadmin/ivr/updateIvr': {
    Input: t.type({
      ivrId: t.string,
      ivrName: t.string,
      ivrJsonStr: t.string,
      publish: t.string,
      type: t.union([t.literal('0'), t.literal('1'), t.literal('2'), t.literal('3'), t.literal('4')]),
    }),
    Output: t.type({}),
  },
  '/tcccadmin/ivr/addIvr': {
    Input: t.type({
      ivrName: t.string,
      ivrJsonStr: t.string,
      publish: t.string,
      type: t.union([t.literal('0'), t.literal('1'), t.literal('2'), t.literal('3'), t.literal('4')]),
    }),
    Output: t.type({}),
  },
  '/tcccadmin/ivr/updateVersionIvr': {
    Input: t.type({
      ivrName: t.string,
      ivrId: t.string,
      ivrJsonStr: t.string,
      publish: t.string,
      version: t.string,
      description: t.string,
      type: t.union([t.literal('0'), t.literal('1'), t.literal('2'), t.literal('3'), t.literal('4')]),
    }),
    Output: t.type({}),
  },
  '/tcccadmin/ivr/uploadIvr': {
    Input: t.type({}),
    Output: t.type({}),
  },
  '/tcccadmin/user/setUserMark': {
    Input: t.type({
      userId: t.string,
      useMark: t.string,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/staff/setConfig': {
    Input: t.partial({
      useMobileCallOut: t.boolean,
      useMobileAcceptType: t.union([t.literal(0), t.literal(1), t.literal(2)]),
    }),
    Output: t.type({}),
  },
  '/tcccadmin/staff/getConfig': {
    Input: t.type({}),
    Output: t.type({
      canUseMobile: t.boolean,
      useMobileCallOut: t.boolean,
      useMobileAcceptType: t.union([t.literal(0), t.literal(1), t.literal(2)]),
    }),
  },
  '/tcccadmin/feedbackqueue/queryQueueCount': {
    Input: t.type({
      userId: t.string,
    }),
    Output: t.type({
      count: numberFromString,
    }),
  },
  '/tcccadmin/staff/setAssignAccept': {
    Input: t.type({
      assignCall: t.boolean,
      assignIM: t.boolean,
    }),
    Output: t.type({}),
  },
  '/tcccadmin/staff/getAssignAccept': {
    Input: t.type({}),
    Output: t.type({
      existCall: t.boolean,
      assignCall: t.boolean,
      existIM: t.boolean,
      assignIM: t.boolean,
    }),
  },
  '/tcccadmin/extension/getList': {
    Input: t.type({
      pageNum: numberFromString,
      pageSize: numberFromString,
    }),
    Output: t.type({
      total: numberFromString,
      extensionList: nullableArray(
        t.type({
          extensionId: t.string,
          extensionName: t.string,
          fullExtensionId: t.string,
          relationName: t.string,
          relation: t.string,
          skillGroupId: t.string,
          register: t.boolean,
        }),
      ),
    }),
  },
  '/tcccadmin/num/getNumberGroupList': {
    Input: t.partial({
      pageNum: t.string,
      pageSize: t.string,
    }),
    Output: t.type({
      numGroupList: nullableArray(
        t.type({
          groupDesc: t.string,
          groupId: t.string,
          groupName: t.string,
          canCalloutCount: t.string,
          numberCount: t.string,
        }),
      ),
      total: numberFromString,
    }),
  },
  '/tcccadmin/ivr/getIvrList': {
    Input: t.intersection([
      t.type({
        type: t.string,
      }),
      t.partial({
        pubState: t.string, // 1: ok 2: pending 3: associated 4: rejected
        pageNum: numberFromString,
        pageSize: numberFromString,
      }),
    ]),
    Output: t.type({
      total: numberFromString,
      ivrList: nullableArray(
        t.type({
          ivrId: t.string,
          ivrName: t.string,
        }),
      ),
    }),
  },
  '/tcccadmin/selfservice/getList': {
    Input: t.partial({
      pageNum: t.number,
      pageSize: t.number,
      status: booleanFromNumber0or1,
      source: t.number, // 0: tel , 1: audio
    }),
    Output: t.type({
      total: t.number,
      selfServiceList: nullableArray(
        t.type({
          id: t.number,
          name: t.string,
          assocIvrId: t.number,
        }),
      ),
    }),
  },
  '/tcccadmin/cdr/getTelCdr': {
    Input: t.partial({
      pageNum: numberFromString,
      pageSize: numberFromString,
      sessionIds: t.array(t.string),
      caller: t.string,
      callee: t.string,
      groupIds: t.array(t.string),
      startTimeStamp: t.string,
      endTimeStamp: t.string,
      callType: t.string,
      direction: t.string,
      type: t.string,
      remark: t.string,
      state: t.array(t.number),
      hangupSide: t.array(t.string),
      seat_types: t.array(t.string),
      orderBy: t.array(
        t.type({
          field: t.union([t.literal('timestamp'), t.literal('duration')]),
          asc: t.boolean,
        }),
      ),
      staff: t.type({
        userId: t.string,
        staffName: t.string,
        staffNo: t.string,
      }),
    }),
    Output: t.type({
      total: t.string,
      cdrTelList: nullableArray(
        t.partial({
          callTime: t.string,
          direction: t.string,
          caller: t.string,
          callee: t.string,
          protectedCaller: t.string,
          protectedCallee: t.string,
          remark: t.string,
          callEndStatus: t.string,
          duration: t.string,
          groupId: t.string,
          isSelfOwnedNumber: t.boolean,
          staff: t.partial({
            mobile: t.string,
            nickName: t.string,
            sdkAppId: t.string,
            staffNo: t.string,
            userId: t.string,
          }),
          postIVRKeyPressed: t.union([
            nullableArray(
              t.type({
                key: t.string,
                label: t.string,
              }),
            ),
            t.null,
          ]),
          hangupSide: t.string,
          asrUrl: t.string,
          recordUrl: t.string,
          ringTimestamp: t.string,
          endedTimestamp: t.string,
          ivrDuration: t.string,
          ivrKeyPressed: t.string,
          endStatusText: t.string,
          uid: t.string,
        }),
      ),
    }),
  },
  '/tcccadmin/app/getCommKV': {
    Input: t.type({
      key: t.string,
    }),
    Output: t.type({
      value: t.string,
    }),
  },
  '/tcccadmin/chat/queryUserChatHistoryTask': {
    Input: t.partial({
      direct: t.string,
      size: t.number,
      userId: t.string,
      sessionId: t.string,
      userName: t.string,
      roomId: t.string,
    }),
    Output: t.type({
      totalCount: t.number,
      details: nullableArray(
        t.type({
          nickName: t.string,
          seatName: t.string,
          type: t.string,
          cursor: t.string,
          detail: t.string,
        }),
      ),
    }),
  },
  '/tcccadmin/app/getIMAgentListForContactUser': {
    Input: t.type({}),
    Output: t.type({
      totalCount: t.number,
      data: nullableArray(
        t.type({
          imSdkAppId: t.number,
          imAgentUserId: t.string,
          imAgentUserName: t.string,
          imAgentAvatarURL: t.string,
          ivrId: t.number,
          satisfactionId: t.number,
          useUIKit: t.boolean,
        }),
      ),
    }),
  },
  '/tcccadmin/chat/getIMCDR': {
    Input: t.partial({
      fromTime: t.number,
      toTime: t.number,
      pageNumber: t.number,
      pageSize: t.number,
      searchPhrases: t.array(t.string),
      staffNameOrNickName: t.string,
      skillGroupIds: t.array(t.number),
      conversationType: t.number,
    }),
    Output: t.type({
      totalCount: t.number,
      data: nullableArray(
        t.type({
          timestamp: t.number,
          source: t.number,
          userId: t.string,
          userNickName: t.string,
          endStatus: t.number,
          satisfaction: t.string,
          waitingTime: t.number,
          duration: t.number,
          sessionId: t.string,
          staffName: t.string,
          staffNumber: t.string,
          staffUserId: t.string,
          skillGroupName: t.string,
          docCount: t.number,
          conversationType: t.number,
          channelAgentID: t.string,
        }),
      ),
    }),
  },
  '/tcccadmin/cdr/getTraceEvent': {
    Input: t.type({
      sessionId: t.string,
      startTimestamp: t.number,
      endTimestamp: t.number,
    }),
    Output: t.type({
      data: nullableArray(
        t.type({
          eventType: t.string,
          timestampUs: t.number,
          eventDetail: t.string,
        }),
      ),
    }),
  },
};
