import * as t from 'io-ts';
import { Span, context, propagation, trace } from '@opentelemetry/api';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import {
  UserStatus,
  UserStatusEnum,
  LogicStatusType,
  UserStatusType,
  getOrPromiseReject,
  LogicStateType,
} from 'tccc-utils';
import Logger from 'tccc-logger';
import { RtcSocket } from '../socket/socket';
import { TIM } from '../tim/tim';
import { TcccSdk } from '../tccc';
import { axios } from '../http/axios';
import { UseMobileAccept, UseMobileCallOut } from '../constants/sessions';
import traceContext, { contextWithSpan } from '../http/tracer';
import loggerContext from '../http/logger';
import { loginAPIs, loginInstanceResponseC } from '../http/apis/login';
// import { closeLocalStream } from './createLocalStream';
import { queryQueueCount } from '../timer/queryQueueCount';
import { NumberReflectMode } from '../constants/appSettings';
import mapKeys from 'lodash/mapKeys';
import { getLogger } from '../common/Logger';
import i18next, { getBackendLanguage } from '../i18n/i18next.config';
import { TRACE_PARENT_HEADER } from '@opentelemetry/core';
import { BaseClass } from './BaseClass';
import {
  GET_AEGIS_ID,
  GET_AEGIS_URL,
  GET_API_BAK_DOMAIN,
  GET_API_DOMAIN,
  GET_OTLP_DOMAIN,
  GET_OTLP_ID,
} from 'tccc-env/src/url';
import { isInternational } from 'tccc-env/src/env';
import { setAegisConfig } from '../http/aegis';
import { bootstrap, getEnvName } from '../http/opentelemetry';
import { COMMIT_ID } from '../constants/env';
import { createTRTC, destroyTRTC } from '../trtc/memoTRTC';

export type AppSettings = {
  callTimeout: number;
  imTimeout: number;
  audioTimeout: number;
  videoTimeout: number;
  endCountdown: number;
  imEndCountdown: number;
  audioEndCountdown: number;
  imAutoAccept: boolean;
  telAutoAccept: boolean;
  audioAutoAccept: boolean;
  videoAutoAccept: boolean;
  hideCalloutNumber: boolean;
  hideCallinNumber: boolean;
  numberReflectMode: NumberReflectMode;
  telCallinPostIVR: boolean;
  smsPrivilege: boolean;
  seatType: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  autoRealtimeAsr: boolean;
  realtimeAsr: boolean;
  telBlackUserEnable: boolean;
  staffAssignedCalloutNumber: boolean;
  imContactUserEnable: boolean;
  staffShowReadReceipt: number;
  hasUseMobileStaff: boolean;
};
export type AssignConfig = {
  assignCall?: boolean;
  assignIM?: boolean;
  existCall?: boolean;
  existIM?: boolean;
};
export type AgentStateType = {
  status: UserStatus;
  logicState?: LogicStateType;
  logicStatus?: LogicStatusType;
  reason?: string;
  IM: {
    total: number;
    used: number;
  };
  queueCount?: Record<string, number>;
} & AssignConfig;

export type UserInfo = {
  sdkAppId: string;
  userId: string;
  nickname: string;
  staffName: string;
  staffNo: string;
  mobile: string;
  canUseMobile: boolean;
  useMobileAcceptType: UseMobileAccept;
  useMobileCallOutType: UseMobileCallOut;
  isBindMobile?: boolean;
  skillGroupId?: string[];
  roleId: string;
  imSig: string;
  origin: string; // SDK域名来源
};
const userInfoInitialState: UserInfo = {
  sdkAppId: '',
  userId: '',
  nickname: '',
  staffName: '',
  staffNo: '',
  mobile: '',
  canUseMobile: false,
  useMobileCallOutType: UseMobileCallOut.off,
  useMobileAcceptType: UseMobileAccept.off,
  isBindMobile: false,
  skillGroupId: [],
  roleId: '',
  imSig: '',
  origin: window.origin,
};
export const initialState: AgentStateType = {
  status: UserStatus.offline,
  logicState: undefined,
  logicStatus: undefined,
  reason: '',
  IM: {
    total: 0,
    used: 0,
  },
  assignCall: true,
  assignIM: true,
  existCall: true,
  existIM: true,
  queueCount: {},
};
export const settingsInitialState: AppSettings = {
  callTimeout: 15,
  imTimeout: 15,
  audioTimeout: 15,
  videoTimeout: 15,
  endCountdown: 15,
  imEndCountdown: 15,
  audioEndCountdown: 15,
  imAutoAccept: false,
  telAutoAccept: false,
  audioAutoAccept: false,
  videoAutoAccept: false,
  hideCalloutNumber: false,
  hideCallinNumber: false,
  numberReflectMode: NumberReflectMode.close,
  seatType: 2,
  telCallinPostIVR: false,
  smsPrivilege: false,
  autoRealtimeAsr: false,
  realtimeAsr: false,
  telBlackUserEnable: false,
  staffAssignedCalloutNumber: false,
  imContactUserEnable: false,
  staffShowReadReceipt: 0,
  hasUseMobileStaff: false,
};
/**
 * 设置座席状态
 */
const setStatus = <T extends keyof typeof UserStatus>(rtcSocket: RtcSocket, state: T, reason?: string) =>
  rtcSocket.setStatus(state, reason);

const getNextStatus = (payload: Partial<AgentStateType>) => {
  if (payload.status === UserStatus.busy) {
    if (payload.logicStatus === 'notReady') {
      return UserStatusEnum.notReady;
    }
    if (payload.logicStatus === 'rest') {
      return UserStatusEnum.rest;
    }
  }
};
/*
 * 内部修改agentState
 * 外呼触发回调
 */
type ChangedState = Partial<AgentStateType> & { logicStatus: LogicStatusType };
export const emitAgentState = (emitter: TcccSdk, state: AgentStateType) => {
  const { agentState } = emitter.Agent;
  const originState: ChangedState = JSON.parse(JSON.stringify(agentState));
  const nextState = pick(state, Object.keys(initialState));
  const payload: ChangedState = {
    logicStatus: nextState.logicStatus || originState.logicStatus,
    IM: nextState.IM || originState.IM,
    reason: nextState.reason || originState.reason,
    queueCount: nextState.queueCount,
  };
  if (
    originState.status !== nextState.status ||
    originState.logicStatus !== nextState.logicStatus ||
    originState.logicState !== nextState.logicState
  ) {
    payload.status = nextState.status;
    payload.logicStatus = nextState.logicStatus || originState.logicStatus;
    payload.logicState = nextState.logicState;
  }
  if (!isEqual(pick(agentState, Object.keys(payload)), payload)) {
    emitter.Agent.setStatusAction(payload);
    const status =
      typeof UserStatus[payload.status as UserStatus] !== undefined
        ? (UserStatus[payload.status as UserStatus] as UserStatusType)
        : undefined;
    emitter.emit('agentStateChanged', {
      agentStauts: `${payload.status}`,
      status,
      logicStatus: payload.logicStatus,
      restReason: payload.reason,
      nextStatus: getNextStatus(payload),
      IM: payload.IM,
      queueCount: payload.queueCount,
    });
  }
};

export type UserSettingsType = Partial<{
  useMobileAcceptType: UseMobileAccept;
  useMobileCallOutType: UseMobileCallOut;
}>;

export const emitUserSettings = (emitter: TcccSdk, settings: UserSettingsType) => {
  const { userInfo } = emitter.Agent;
  if (!userInfo) return;
  const newSettings: Required<UserSettingsType> = JSON.parse(
    JSON.stringify({
      useMobileAcceptType: userInfo.useMobileAcceptType,
      useMobileCallOutType: userInfo.useMobileCallOutType,
    }),
  );

  let changed = false;

  Object.entries(settings).forEach(([item, value]) => {
    if (value !== undefined && userInfo[item as keyof UserSettingsType] !== value) {
      changed = true;
      Object.assign(newSettings, {
        [item]: value,
      });
    }
  });

  if (changed) {
    emitter.Agent.updateUseMobileConfig(newSettings);
    emitter.emit('userSettingsUpdated', newSettings);
  }
};

const tokenParams = t.type({
  sdkAppId: t.string,
  userId: t.string,
  token: t.string,
  origin: t.union([t.undefined, t.string]),
});
const cookieParams = t.type({
  userId: t.string,
  sdkAppId: t.string,
});
const loginParams = t.union([tokenParams, cookieParams]);

export class Agent implements BaseClass {
  sdk: TcccSdk;
  userInfo: UserInfo | null;
  settings: AppSettings;
  sessionKey: string | null;
  agentState: AgentStateType;
  logger: ReturnType<typeof getLogger>;
  loginResponse: Record<string, t.TypeOf<typeof loginAPIs['/tccclogin/login/loginInstance']['Output']>> = {};
  origin: string;
  isInternational: boolean;
  enableShared: boolean;
  createLocalStreamOnLogin: boolean;

  constructor(sdk: TcccSdk, origin: string) {
    this.sdk = sdk;
    this.origin = origin;
    this.isInternational = isInternational(origin);
    traceContext.tracer = bootstrap(this.isInternational);
    loggerContext.logger = new Logger({
      url: `https://${GET_OTLP_DOMAIN(origin)}/v1/logs`,
      id: GET_OTLP_ID(origin),
      name: 'tccc-sdk',
      version: COMMIT_ID,
      attributes: {
        commit: COMMIT_ID,
        env_Name: getEnvName(),
      },
    });
    const baseURL = `https://${GET_API_DOMAIN(origin)}`;
    const backupURL = `https://${GET_API_BAK_DOMAIN(origin)}`;
    setAegisConfig({
      id: GET_AEGIS_ID(origin),
      url: GET_AEGIS_URL(origin),
    });
    axios.defaults.baseURL = baseURL;
    /// @ts-ignore
    axios.defaults.backupURL = backupURL;

    this.userInfo = null;
    this.sessionKey = null;
    this.settings = settingsInitialState;
    this.agentState = initialState;
    this.logger = getLogger(null, () => this.userInfo);
    this.enableShared = false;
    this.createLocalStreamOnLogin = true;
  }
  reset() {
    // this.userInfo = null;
    // this.sessionKey = null;
    // this.settings = settingsInitialState;
    // this.agentState = initialState;
  }

  setStatus(
    params: Parameters<typeof setStatus>[1] | { status: Parameters<typeof setStatus>[1]; restReason?: string },
    restReason?: string,
  ) {
    if (this.sdk.socket) {
      // 兼容SDK API调用
      if (typeof params === 'object') {
        return setStatus(this.sdk.socket, params.status, params.restReason);
      }
      return setStatus(this.sdk.socket, params, restReason);
    }
    throw new Error('Authorization failed');
  }

  setStatusAction(state: Partial<AgentStateType>) {
    this.agentState = {
      ...this.agentState,
      ...state,
    };
  }
  async fetchAppInfo() {
    let appInfo = null;
    try {
      appInfo = await this.sdk.http.request('/tcccadmin/app/getAppInfo');
      this.updateSettings(appInfo);
    } catch (error: any) {}
  }
  updateSettings(appInfo: Partial<AppSettings>) {
    this.settings = {
      ...this.settings,
      ...mapKeys(appInfo, (_, key) => key.replace('CountDown', 'Countdown').replace('TimeOut', 'Timeout')),
      numberReflectMode: +(appInfo?.numberReflectMode || 0) as NumberReflectMode,
    };
  }
  updateUseMobileConfig(
    config: Partial<{
      useMobileAcceptType: UseMobileAccept;
      useMobileCallOutType: UseMobileCallOut;
    }>,
  ) {
    if (!this.userInfo) return;
    if (config.useMobileAcceptType !== undefined) {
      this.userInfo.useMobileAcceptType = config.useMobileAcceptType;
    }
    if (config.useMobileCallOutType !== undefined) {
      this.userInfo.useMobileCallOutType = config.useMobileCallOutType;
    }
  }

  createAgent = (params: t.TypeOf<typeof loginParams>) => {
    const metaElement = Array.from(document.getElementsByTagName('meta')).find(
      (e) => e.getAttribute('name') === TRACE_PARENT_HEADER,
    );
    const traceparent = metaElement?.content || '';
    return context.with(
      propagation.extract(context.active(), {
        [TRACE_PARENT_HEADER]: traceparent,
      }),
      async () => {
        const span = traceContext.tracer.startSpan('createAgent', { attributes: params });
        const tokenLogin = 'token' in params && params.token;
        const response = tokenLogin ? await this.loginByToken(params) : await this.loginByCookie(params);
        this.sessionKey = response.session_key;
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        this.userInfo = { sdkAppId: params.sdkAppId } as UserInfo;
        const res = await this.handleLoginResponse(response, params.sdkAppId, span, window.origin);
        this.userInfo = res.userInfo;
        this.setStatusAction(res.agentAssignConfig);
        this.updateSettings(res.appInfo);
        span.end();
        return res;
      },
    );
  };

  online = async (
    {
      enableShared,
      createLocalStreamOnLogin,
    }: {
      createLocalStreamOnLogin: boolean;
      enableShared: boolean;
    } = {
      createLocalStreamOnLogin: this.createLocalStreamOnLogin,
      enableShared: this.enableShared,
    },
  ) => {
    if (!this.userInfo || !this.sessionKey) {
      throw new Error(i18next.t('Authorization failed'));
    }
    // 以最新为准
    this.enableShared = enableShared;
    this.createLocalStreamOnLogin = createLocalStreamOnLogin;

    const span = traceContext.tracer.startSpan('online', { attributes: { createLocalStreamOnLogin, enableShared } });
    try {
      if (!this.sdk.socket) {
        this.sdk.socket = new RtcSocket({ sdk: this.sdk, enableShared });
      }
      if (!this.sdk.tim) {
        this.sdk.tim = new TIM(this.sdk);
      }
      await this.sdk.socket.login({
        sdkAppId: this.userInfo.sdkAppId,
        userId: this.userInfo.userId,
        sessionKey: this.sessionKey,
      });
      // IM SDK登录失败不影响核心逻辑
      this.sdk.tim
        .login({
          sdkAppId: this.userInfo.sdkAppId,
          userId: this.userInfo.userId,
          userSig: this.userInfo.imSig,
        })
        .then(() => {
          this.logger.info('IM SDK login success');
          return this.sdk.getConversationList();
        })
        .catch((e) => {
          this.logger.error('IM SDK login error', e);
        });
      try {
        if (createLocalStreamOnLogin) {
          await createTRTC({ sdkAppId: this.userInfo.sdkAppId, userId: this.userInfo.userId });
        }
      } catch (e) {
        this.logger.info('create local stream failed when online');
      }
      this.startTime();
      span.end();
    } catch (e) {
      const error = e as Error & { msg?: string };
      span.recordException(error);
      span.end();
      if (error.msg) {
        error.message = error.msg;
      }
      this.sdk.socket?.logout(error.message);
      this.sdk.tim?.logout();
      this.stopTimer();
      throw error;
    }
  };

  offline = () => {
    this.logger.info('offline()');
    this.stopTimer();
    this.sdk.socket?.logout();
    this.sdk.tim?.logout();
    this.sdk.Call.reset();
    this.sdk.Chat.reset();
    this.sdk.Agent.reset();
    if (this.userInfo) {
      destroyTRTC(this.userInfo);
    }
  };

  getStatus = () => UserStatus[this.agentState.status];

  changeLanguage = (lang: string) => {
    this.sdk.socket?.setLanguage(getBackendLanguage(lang));
  };

  private async loginByToken(params: t.TypeOf<typeof tokenParams>) {
    const span = traceContext.tracer.startSpan('loginByToken', { attributes: params });
    const { sdkAppId, userId, token, origin: sdkOrigin } = await getOrPromiseReject(tokenParams, span)(params);
    const origin = sdkOrigin || window.origin;
    try {
      const response = await contextWithSpan(span, () =>
        this.sdk.http.request('/tccclogin/login/checkSDKLoginToken', {
          staff: {
            userid: userId,
          },
          token,
          sdkAppId,
          origin,
        }),
      );
      span.end();
      Object.assign(this.loginResponse, {
        [sdkAppId + userId]: response,
      });
      return response;
    } catch (e) {
      span.recordException(e as Error);
      span.end();
      throw e;
    }
  }

  private async loginByCookie(params: t.TypeOf<typeof cookieParams>) {
    const span = traceContext.tracer.startSpan('loginByCookie', { attributes: params });
    const { sdkAppId, userId } = await getOrPromiseReject(cookieParams, span)(params);
    try {
      const res = await contextWithSpan(span, () =>
        this.sdk.http.request('/tccclogin/login/loginInstance', {
          userId,
          sdkAppId,
        }),
      );
      span.end();
      return res;
    } catch (error) {
      // fixme: 使用token登录响应值解决三方cookie登录失败的问题
      if (`${sdkAppId}${userId}` in this.loginResponse && this.loginResponse[sdkAppId + userId]) {
        this.logger.warn('check login failed, use memory response data');
        span.end();
        return this.loginResponse[sdkAppId + userId];
      }
      span.recordException(error as Error);
      span.end();
      throw error;
    }
  }

  private handleLoginResponse = async (
    response: t.TypeOf<typeof loginInstanceResponseC> & {
      session_key: string;
    },
    sdkAppId: string,
    parentSpan: Span,
    origin: string = window.origin,
  ): Promise<{
    userInfo: UserInfo;
    appInfo: AppSettings;
    appSettings: Partial<AppSettings>;
    agentAssignConfig: AssignConfig;
    sessionKey: string;
  }> => {
    const span = traceContext.tracer.startSpan(
      'handleLoginResponse',
      { attributes: { sdkAppId, userId: response.staff.email } },
      trace.setSpan(context.active(), parentSpan),
    );
    const [config, assignConfig, appInfo] = await Promise.all([
      contextWithSpan(span, () => this.sdk.http.request('/tcccadmin/staff/getConfig')),
      contextWithSpan(span, () =>
        this.sdk.http
          .request('/tcccadmin/staff/getAssignAccept')
          .then(({ existCall, existIM, assignCall, assignIM }) => ({
            existCall,
            existIM,
            assignCall,
            assignIM,
          })),
      ),
      contextWithSpan(span, () => this.sdk.http.request('/tcccadmin/app/getAppInfo')),
    ]);
    const { im_sig: imSig, staff } = response;

    // 兼容loginInstance返回undefined逻辑，后台发布后可以删除
    let { isBindMobile } = response;
    if (typeof isBindMobile === 'undefined') {
      isBindMobile = !!staff.mobile;
    }
    const userInfo: UserInfo = {
      ...userInfoInitialState,
      sdkAppId,
      userId: staff.email,
      roleId: staff.roleId,
      nickname: staff.nickName,
      staffName: staff.staffName,
      staffNo: staff.staffNo,
      skillGroupId: staff.skillGroupId,
      mobile: staff.mobile,
      isBindMobile,
      canUseMobile: config.canUseMobile,
      useMobileCallOutType: config.useMobileCallOut ? UseMobileCallOut.on : UseMobileCallOut.off,
      useMobileAcceptType: config.useMobileAcceptType as UseMobileAccept,
      imSig,
      origin,
    };
    const appSettings: Partial<AppSettings> = {
      seatType: appInfo.seatType,
      smsPrivilege: response.appInfo.smsPrivilege,
    };
    try {
      span.addEvent('userInfo', {
        userInfo: JSON.stringify(userInfo),
        appSettings: JSON.stringify(appSettings),
        assignConfig: JSON.stringify(assignConfig),
      });
    } catch (e) {
      span.recordException(e as Error);
    } finally {
      span.end();
    }

    return {
      userInfo,
      appInfo: {
        ...settingsInitialState,
        ...appInfo,
      },
      appSettings,
      agentAssignConfig: assignConfig,
      sessionKey: response.session_key,
    };
  };
  private startTime() {
    this.sdk.timer.on('queryQueueCount', () => queryQueueCount(this.sdk), 30000);
    this.sdk.timer.on('fetchAppInfo', () => this.fetchAppInfo(), 60000);
    this.sdk.once('expired', this.offline);
    this.sdk.once('kickedOut', this.offline);
    this.sdk.once('forcedOffline', this.offline);
  }
  private stopTimer() {
    this.sdk.timer.clear();
  }
}
